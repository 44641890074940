'use strict';

import axios from "axios";

export default {
    retrieve:(context, data) => {
        return new Promise(done => {
            axios.get(`/api/menu/render/${data.id}`).then(res => {
                if (res.data.success) {
                    done(res.data.data.body);
                }
            }).catch(err => {
                done(err);
            });
        });
    }
}